import { ReactNode } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ZDialog } from '@zep/ui';
import { cn } from '@zep/utils';
import { useTranslation } from 'next-i18next';

export type CustomAlertProps = {
  type: 'confirm' | 'alert' | 'danger';
  title: ReactNode;
  content?: ReactNode;
  cancelText?: ReactNode;
  cancelAction?: () => void;
  confirmText?: ReactNode;
  useOutSideClick?: boolean;
};

export function _CustomAlert(props: CustomAlertProps) {
  const { t } = useTranslation();
  const { type = 'alert', useOutSideClick = true } = props;
  const modal = useModal();
  const {
    Dialog,
    DialogPanel,
    DialogHeader,
    DialogContent,
    DialogTitle,
    DialogFooter,
  } = ZDialog;

  return (
    <Dialog
      maxWidth={'320px'}
      open={modal.visible}
      onClose={() => {
        if (!useOutSideClick) return false;
        modal.remove();
      }}>
      <DialogPanel>
        <DialogHeader>
          <DialogTitle>{props.title}</DialogTitle>
        </DialogHeader>
        <DialogContent className={'h-auto py-0'}>
          <div className="text-start text-[14px] font-normal leading-normal text-gray-700">
            {props.content}
          </div>
        </DialogContent>
        <DialogFooter className="flex items-center gap-xs pt-[24PX]">
          {type !== 'alert' && (
            <button
              className="h-[40px] flex-1 rounded bg-gray-100 text-[14px] font-semibold leading-normal text-gray-600"
              onClick={() => {
                props.cancelAction && props.cancelAction();
                modal.remove();
              }}>
              {props.cancelText || t('global.cancel.button', '취소')}
            </button>
          )}
          <button
            className={cn(
              'flex-1 h-[40px] text-[14px] font-semibold leading-[1.4] rounded',
              type === 'danger'
                ? 'text-red bg-[#fff2f7] border-black/[3%]'
                : 'text-white bg-primary',
            )}
            onClick={() => {
              modal.resolve();
              modal.remove();
            }}>
            {props.confirmText || t('global.confirm.button', '확인')}
          </button>
        </DialogFooter>
      </DialogPanel>
    </Dialog>
  );
}

export const CustomAlert = NiceModal.create(_CustomAlert);
