import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { NoSSR } from '@zep/components';
import { SupportedLocale } from '@zep/consts/localize';
import { HomeLogo } from '@zep/layout';
import { HeaderContainer } from '@zep/layout/Header/HeaderContainer.tsx';
import {
  MobileDrawer,
  TriggerContent,
} from '@zep/layout/Header/MobileDrawer.tsx';
import {
  AuthButton,
  CreateNewQuizButton,
} from '@zep/layout/Header/UserManagementButton.tsx';
import { Button } from '@zep/ui';
import { zepAnalytics } from '@zep/utils';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { EnterCodeButton, ExploreButton } from './PageNavigatorButton';

export const GNB_BUTTON_INFO: Record<
  'pricing' | 'public' | 'notice' | 'guide',
  {
    link: string;
    text: string;
    track: string;
    icon?: string | ReactNode;
    hideLocales: SupportedLocale[];
    target: HTMLAttributeAnchorTarget;
  }
> = {
  pricing: {
    link: '/pricing',
    target: '_self',
    icon: '/assets/icon_magnifying_glass.svg',
    text: 'common.header.pricing',
    track: '',
    hideLocales: [],
  },
  public: {
    link: '/public',
    target: '_self',
    text: 'header.explore.button',
    track: '',
    hideLocales: [],
  },
  guide: {
    link: 'common.header.guideLink',
    target: '_blank',
    text: 'common.header.userGuide',
    track: '',
    hideLocales: ['en', 'ja'],
  },
  notice: {
    link: 'common.header.noticeLink',
    target: '_blank',
    text: 'common.header.notice',
    track: '',
    hideLocales: ['ja'],
  },
};

export function GnbHeader() {
  return (
    <HeaderContainer>
      <div className="flex items-center gap-[24px]">
        <HomeLogo location={'header'} />
        <div className={'gap hidden gap-sm md:flex'}>
          <LeftButtons />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <div className={'flex md:hidden'}>
          <ExploreButton.Icon />
        </div>
        <AuthButton />
        <EnterCodeButton type={'normal'} />
        <NoSSR fallback={<TriggerContent />}>
          <MobileDrawer />
        </NoSSR>
        <CreateNewQuizButton type={'normal'} />
      </div>
    </HeaderContainer>
  );
}

const LeftButtons = () => {
  const {
    t,
    i18n: { resolvedLanguage = 'ko' },
  } = useTranslation();

  return Object.values(GNB_BUTTON_INFO)
    .filter(l => !l.hideLocales.includes(resolvedLanguage as SupportedLocale))
    .map(d => (
      <Button size={'sm'} key={d.link} transParented intent={'neutral'} asChild>
        <Link
          href={d.link.startsWith('common') ? t(d.link) : d.link}
          target={d.target}
          prefetch={false}
          onClick={() => {}}>
          {t(d.text)}
        </Link>
      </Button>
    ));
};

export const GnbHeaderShareClass = 'hidden md:flex';

export const trackGnbAnalytics = (
  to: string,
  location: string,
  userName?: string,
) => {
  zepAnalytics.track(`enter_${to}`, {
    location,
    ...(to === 'create' && { name: userName }),
  });
};
