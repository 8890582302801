import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCheckFlowerFill = (
  props: SVGProps<SVGSVGElement> & {
    defsId?: string;
  },
) => {
  const { fill, ...restProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.01 2.012a3.2 3.2 0 0 0-2.267.942l-.002.001-.696.696v.001A1.2 1.2 0 0 1 8.2 4h-1A3.2 3.2 0 0 0 4 7.2v1a1.2 1.2 0 0 1-.348.844v.001l-.697.696v.002a3.2 3.2 0 0 0 0 4.535v.002l.696.696h.001a1.2 1.2 0 0 1 .348.845v1a3.2 3.2 0 0 0 3.2 3.2h1c.316 0 .62.126.845.349l.696.696h.001a3.2 3.2 0 0 0 4.536 0h.002l.696-.696v-.001a1.2 1.2 0 0 1 .845-.349h1a3.2 3.2 0 0 0 3.2-3.2v-.999c0-.316.125-.62.348-.845l.697-.696v-.002a3.2 3.2 0 0 0 0-4.536l-.697-.697a1.2 1.2 0 0 1-.349-.845v-1a3.2 3.2 0 0 0-3.2-3.2h-1c-.315 0-.619-.125-.843-.348h-.001l-.696-.697h-.002a3.2 3.2 0 0 0-2.267-.943m3.788 8.32a.8.8 0 1 0-1.196-1.063l-3.668 4.127-1.536-1.727a.8.8 0 1 0-1.195 1.063l2.133 2.4a.8.8 0 0 0 1.196 0z"
        fill={fill ? fill : '#5D5E69'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_6156_9272"
          x1="1.34082"
          y1="8.00665"
          x2="14.6725"
          y2="8.00665"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8478FF" />
          <stop offset="1" stopColor="#3DA9FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgCheckFlowerFill;
