import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ProChip } from '@zep/components/chip';
import { NameEditModal } from '@zep/components/modals/NameEditModal.tsx';
import { useUser } from '@zep/lib/auth';
import { Button } from '@zep/ui';
import { cn } from '@zep/utils';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { MyMenuDropdown, trackGnbAnalytics } from './';

type HeaderButtonProps = { type: 'drawer' | 'normal' };

export const AuthButton = () => {
  const { user, isInitialization } = useUser();

  if (!isInitialization) {
    return null;
  }

  if (user) {
    return <MyMenuDropdown />;
  } else {
    return <LogInButton type={'normal'} />;
  }
};

export const LogInButton = ({ type }: HeaderButtonProps) => {
  const { t } = useTranslation();

  if (type === 'drawer') {
    return (
      <Button
        intent={'neutral'}
        transParented
        asChild
        className={cn('text-[15px] flex gap-3xs justify-start h-[48px]')}>
        <Link
          href={'/login'}
          onClick={() => trackGnbAnalytics('login', 'Header')}>
          <img src="/assets/icon_login.svg" alt="login-icon" />
          {t('header.login', '로그인')}
        </Link>
      </Button>
    );
  }

  return (
    <Button
      size={'md'}
      transParented
      intent={'neutral'}
      asChild
      className={cn('hidden md:flex')}>
      <Link
        href={'/login'}
        onClick={() => trackGnbAnalytics('login', 'Header')}>
        {t('header.login')}
      </Link>
    </Button>
  );
};

export const LogOutButton = ({ isDrawerOpen }: { isDrawerOpen?: boolean }) => {
  const { t } = useTranslation();
  const { logOut } = useUser();

  if (isDrawerOpen) {
    return (
      <Button
        intent={'neutral'}
        transParented
        size={'xs'}
        onClick={() => {
          logOut();
          trackGnbAnalytics('logout', 'Header');
        }}>
        {t('header.logout')}
      </Button>
    );
  }

  return (
    <Button
      onClick={() => {
        logOut();
        trackGnbAnalytics('logout', 'Header');
      }}>
      <img src="/assets/icon_out_red.svg" alt="logout" />{' '}
      <div className="text-[#FF4848]">{t('header.logout')}</div>
    </Button>
  );
};

export const EditNicknameMbButton = () => {
  const { user } = useUser();

  const handleNameEditModalOpen = () => {
    NiceModal.show(NameEditModal, {
      initialName: user?.username,
    });
  };

  return (
    <Button
      className={cn(
        'hover:bg-transparent gap-xs bg-transparent items-center justify-center',
        'max-w-[200px] overflow-hidden truncate px-0 py-[12.5px] text-[15px]',
      )}
      onClick={handleNameEditModalOpen}>
      <ProChip id={'3'} isShow={user?.planType === 'PRO'} />
      <span className="max-w-full truncate text-default">{user?.username}</span>
      <img
        src="/assets/icon_pen_gray_line.svg"
        className="size-[18px]"
        alt="edit"
      />
    </Button>
  );
};

export const CreateNewQuizButton = ({ type }: HeaderButtonProps) => {
  const { t } = useTranslation();
  const { user } = useUser();

  if (type === 'drawer') {
    return (
      <Button
        asChild
        className="border-[rgba(28, 27, 31, 0.04)] flex size-full h-[48px] w-full justify-center gap-3xs border-[1px] bg-primary-foreground
        p-0 py-[13.5px] text-[15px] text-text-strong hover:bg-[#E7E4FF]
        ">
        <Link
          href="/build"
          onClick={() =>
            trackGnbAnalytics('create', 'Header', user?.username || '')
          }>
          <img
            src="/assets/icon_plus_primary.png"
            className="size-[24px]"
            alt="plusicon"
          />
          {t('header.create.button')}
        </Link>
      </Button>
    );
  }

  return (
    <Button
      size={'md'}
      asChild
      className="hidden bg-[#6758FF] p-0 text-white hover:bg-text-strong md:flex">
      <Link
        href="/build"
        onClick={() =>
          trackGnbAnalytics('create', 'Header', user?.username || '')
        }
        className="flex gap-3xs px-xs py-[10px]">
        <img
          src="/assets/icon_plus.png"
          className="size-[18px]"
          alt="plusicon"
        />
        {t('header.create.button')}
      </Link>
    </Button>
  );
};
