import React, { SVGProps } from 'react';

export const ProChip = (
  props: { id: string; isShow: boolean } & SVGProps<SVGSVGElement>,
) => {
  const { id: _id, isShow } = props;
  const id = `paint0_linear_6588_29525_${_id}`;

  if (!isShow) return null;

  return (
    <svg
      width="30"
      height="16"
      viewBox="0 0 30 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <mask id="path-1-inside-1_6627_36058" fill="white">
        <path d="M0 8C0 3.58172 3.58172 0 8 0H21.5606C25.9788 0 29.5606 3.58172 29.5606 8V8C29.5606 12.4183 25.9788 16 21.5606 16H8.00001C3.58173 16 0 12.4183 0 8V8Z" />
      </mask>
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H21.5606C25.9788 0 29.5606 3.58172 29.5606 8V8C29.5606 12.4183 25.9788 16 21.5606 16H8.00001C3.58173 16 0 12.4183 0 8V8Z"
        fill={`url(#${id})`}
      />
      <path
        d="M8 1H21.5606V-1H8V1ZM21.5606 15H8.00001V17H21.5606V15ZM8.00001 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8.00001 17V15ZM28.5606 8C28.5606 11.866 25.4266 15 21.5606 15V17C26.5311 17 30.5606 12.9706 30.5606 8H28.5606ZM21.5606 1C25.4266 1 28.5606 4.13401 28.5606 8H30.5606C30.5606 3.02944 26.5311 -1 21.5606 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
        fill="#1C1B1F"
        fillOpacity="0.04"
        mask="url(#path-1-inside-1_6627_36058)"
      />
      <path
        d="M7.65929 4.24991C7.65929 4.14463 7.59151 4.05132 7.4914 4.01877C7.39128 3.98621 7.28159 4.02181 7.21967 4.10695L3.88633 8.69028C3.83256 8.76422 3.82483 8.86207 3.86631 8.94353C3.90779 9.025 3.99148 9.0763 4.0829 9.0763H6.33985V11.7499C6.33985 11.8552 6.40762 11.9485 6.50774 11.981C6.60785 12.0136 6.71755 11.978 6.77947 11.8929L10.1128 7.30953C10.1666 7.2356 10.1743 7.13775 10.1328 7.05628C10.0913 6.97482 10.0077 6.92352 9.91623 6.92352H7.65929V4.24991Z"
        fill="white"
      />
      <path
        d="M24.0215 11.5889C22.4736 11.5889 21.4824 10.4902 21.4824 8.85449C21.4824 7.2041 22.4736 6.11035 24.0215 6.11035C25.5742 6.11035 26.5654 7.2041 26.5605 8.85449C26.5654 10.4902 25.5742 11.5889 24.0215 11.5889ZM22.7422 8.84473C22.7422 9.81641 23.167 10.5928 24.0312 10.5928C24.8809 10.5928 25.3057 9.81641 25.3008 8.84473C25.3057 7.87305 24.8809 7.0918 24.0312 7.09668C23.167 7.0918 22.7422 7.87305 22.7422 8.84473Z"
        fill="white"
      />
      <path
        d="M18.1133 11.4814V6.17871H19.3242V7.05762H19.3828C19.5732 6.44727 20.0811 6.10059 20.6914 6.10059C20.8281 6.10059 21.0234 6.11523 21.1406 6.12988V7.28223C21.0283 7.24316 20.7695 7.20898 20.5645 7.2041C19.8711 7.20898 19.3633 7.6875 19.3633 8.35645V11.4814H18.1133Z"
        fill="white"
      />
      <path
        d="M12 11.4814V4.41113H14.6562C16.2773 4.41113 17.1416 5.39746 17.1465 6.75488C17.1416 8.10742 16.2676 9.08887 14.6367 9.08887H13.2695V11.4814H12ZM13.2695 8.04395H14.4609C15.418 8.03906 15.8477 7.50195 15.8477 6.75488C15.8477 5.99805 15.418 5.47559 14.4609 5.47559H13.2695V8.04395Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={id}
          x1="0"
          y1="8"
          x2="29.5606"
          y2="8"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8478FF" />
          <stop offset="1" stopColor="#3DA9FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
